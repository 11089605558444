import React, { useState, useEffect } from 'react'
import { message, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import axios from 'axios'
import styled from 'styled-components'
import { NetworkStatus } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import useGetS3PutObjectSignedUrl from 'graphQL/useGetS3PutObjectSignedUrl'

import { useAuthContext } from 'contexts/AuthProvider'

import { checkUserType, getUserId } from 'utils/user'

import LoadingIcon from 'components/LoadingIcon'

import { s3Pathname } from 'config'

import type { RcFile, UploadProps } from 'antd/lib/upload'
import type { ImageProps, UploadImageProps } from './interface'
import { getS3UrlByType } from 'utils/app'

const UploadImage: React.FC<UploadImageProps> = ({ name, value, onUpload }) => {
  const { t } = useTranslation('uploadImage')

  const auth = useAuthContext()

  const [file, setFile] = useState<RcFile | undefined>()
  const [image, setImage] = useState<ImageProps | null>(null)
  const [uploadImageUrl, setUploadImageUrl] = useState<string | null>(null)
  const [isUploadSucces, setUploadSuccess] = useState(false)
  const [isImageUploading, setImageUploading] = useState(false)

  const s3PutObjectQuery = useGetS3PutObjectSignedUrl({
    skip: !file,
    variables: {
      userId: checkUserType(auth.token?.accessToken, 'RESOURCE_OWNER') ? '' : getUserId(auth.token?.accessToken),
      inputs: [
        {
          acl: 'PUBLIC',
          contentType: file?.type,
          objectName: file?.name,
          path: s3Pathname,
        },
      ],
    },
    notifyOnNetworkStatusChange: true,
    onCompleted(resp) {
      const imageData = resp.getS3PutObjectSignedUrl.payload[0]

      setUploadSuccess(false)
      setUploadImageUrl(imageData.signedUrl)
      setImage({
        fileKey: imageData.fileKey,
        url: imageData.signedUrl,
      })
    },
    onError(error) {
      message.error(error.message)

      setImageUploading(false)
    },
  })

  const isLoading =
    isImageUploading || s3PutObjectQuery.loading || s3PutObjectQuery.networkStatus === NetworkStatus.refetch

  const uploadProps: UploadProps = {
    accept: '.jpg,.jpeg,.gif,.png',
    listType: 'picture-card',
    fileList: file ? [file] : [],
    showUploadList: false,
    beforeUpload: (file) => {
      setImageUploading(true)
      setFile(file)

      return false
    },
    name,
  }

  const onImageError = () => {
    setFile(undefined)
    setImage(null)
    setUploadImageUrl(null)
    setUploadSuccess(false)
    setImageUploading(true)

    s3PutObjectQuery.refetch()
  }

  useEffect(() => {
    const doUploadImage = async () => {
      const headers = {
        'content-type': file?.type,
        'x-amz-acl': 'public-read',
      }

      try {
        const resp = await axios.put(String(uploadImageUrl), file, {
          headers,
        })

        const regex = /\?(.*)/i
        const url = resp.config.url?.replace(regex, '')

        const imageData = {
          fileKey: image?.fileKey,
          url,
        }

        setImage(imageData)
        onUpload(imageData)
        setUploadSuccess(true)
      } catch (error) {
        message.error(t('uploadFailedText'))
      } finally {
        setImageUploading(false)
      }
    }

    if (file && !isUploadSucces && uploadImageUrl) {
      doUploadImage()
    }
  }, [file, image, isUploadSucces, onUpload, t, uploadImageUrl])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t('button')}</div>
    </div>
  )

  return <ImageUploadButton {...uploadProps}>{renderUploadChildren()}</ImageUploadButton>

  function renderUploadChildren() {
    const imageUrl = getS3UrlByType()

    if (isLoading) {
      return <LoadingIcon size={16} />
    }

    if (image) {
      return <img src={image.url} alt={image.fileKey} onError={onImageError} />
    }

    if (value) {
      return <img src={`${imageUrl}/${value}`} alt={value} />
    }

    return uploadButton
  }
}

export default UploadImage

const ImageUploadButton = styled(Upload)`
  img {
    width: 100%;
  }

  & > .ant-upload {
    width: 128px;
    height: 128px;

    overflow: hidden;
  }
`
