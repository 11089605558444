import React from 'react'
import { Form } from 'antd'
import dayjs from 'dayjs'

import HooraySubmitButton from 'components/HooraySubmitButton'
import GeneralFormInput from 'components/GeneralFormInput'

import { commonFormProps } from 'utils/antd'

import type { GeneralFormProps } from './interface'

const GeneralForm: React.FC<GeneralFormProps> = ({ generalFormData, fieldList, masterDataList, onFinish }) => {
  return (
    <Form
      {...commonFormProps}
      name="generalForm"
      onFinish={onFinish}
      initialValues={{
        ...generalFormData,
        dateOfBirth: dayjs(generalFormData.dateOfBirth),
      }}
    >
      <GeneralFormInput fieldList={fieldList} masterDataList={masterDataList} />

      <Form.Item>
        <HooraySubmitButton />
      </Form.Item>
    </Form>
  )
}

export default GeneralForm
