import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { Redirect } from 'react-router-dom'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

import DefaultLayout from 'layouts/DefaultLayout'

import useVerifyPasswordEmail from 'graphQL/useVerifyPasswordEmail'

import { routePaths } from 'pages/routeConfig'

import ResetPasswordForm from './ResetPasswordForm'

const ResetPassword: React.FC = () => {
  const { t } = useTranslation('verifyPasswordEmail')

  const [email] = useQueryParam('email', StringParam)
  const [verifyToken] = useQueryParam('verifyToken', StringParam)

  const [verifyPasswordEmail, verifyPasswordEmailResp] = useVerifyPasswordEmail({
    onCompleted() {
      message.success(t('successTitle'))
    },
  })

  if (!email || !verifyToken) {
    return <Redirect to={routePaths.credentialApplicationSignIn} />
  }

  return (
    <DefaultLayout platformName="Hooray.site">
      <ResetPasswordForm
        loading={verifyPasswordEmailResp.loading}
        onFinish={(data) => {
          verifyPasswordEmail({
            variables: {
              input: {
                email,
                verifyToken,
                ...data,
              },
            },
            context: {
              headers: {
                credentialKey: '1234567890',
              },
            },
          })
        }}
      />
    </DefaultLayout>
  )
}

export default ResetPassword
