import React, { createContext, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { changeAntdTheme } from 'dynamic-antd-theme'

import FullScreenLoading from 'components/FullScreenLoading'

import useGetTheme from 'graphQL/useGetTheme'

import apiPath from 'config/api'

import type { ThemeContextData } from './interface'
import type { ThemeAPIPayload, ThemeColor } from 'graphQL/useGetTheme/interface'

const defaultThemeContextData: ThemeContextData = {
  admin: {
    appKey: '',
    color: {
      navAuthBg: '',
      navAuthText: '',
      navBg: '',
      navText: '',
      primary: '',
      secondary: '',
      titleText: '',
    },
    custom: '',
    image: {
      favIcon: '',
      navAuthCollapsedLogo: '',
      navAuthLogo: '',
      navLogo: '',
      webLogo: '',
    },
    isDefault: 'YES',
    isReplaceable: 'NO',
    name: '',
    status: 'ACTIVE',
    text: {
      description: '',
      navTitle: '',
      webTitle: '',
    },
    themeKey: '',
    themeType: 'ADMIN',
  },
  collapsed: false,
  collapseHandler() {},
}

const ThemeContext = createContext<ThemeContextData>(defaultThemeContextData)

const ThemeProvider: React.FC = ({ children }) => {
  const [admin, setAdminTheme] = useState<ThemeAPIPayload>(defaultThemeContextData.admin)
  const [collapsed, setCollapsed] = useState(defaultThemeContextData.collapsed)

  const themeQuery = useGetTheme({
    variables: {
      host: window.location.host,
    },
    context: {
      uri: apiPath.core.systemAdmin,
    },
    onCompleted(resp) {
      onThemeHandler(resp.getTheme.payload[0])
    },
  })

  const onThemeHandler = (theme: ThemeAPIPayload) => {
    setupTheme(theme)
    setAdminTheme(theme)
  }

  const collapseHandler = () => {
    setCollapsed(!collapsed)
  }

  const setupTheme = (theme: ThemeAPIPayload) => {
    setupThemeColor(theme.color)

    if (theme.image) {
      const { favIcon } = theme.image
      const favEle = document.getElementById('favicon') as HTMLLinkElement
      if (favIcon && favEle) {
        favEle.href = favIcon
      }
    }
  }

  const setupThemeColor = (color: ThemeColor | null) => {
    const fallbackColor = {
      primary: '#006A2B',
      secondary: '#7E7E7E',
      text: '#000',
      navText: '#fff',
    }

    const primary = color?.primary || fallbackColor.primary
    const secondary = color?.secondary || fallbackColor.primary
    const text = color?.titleText || fallbackColor.text

    changeAntdTheme(primary)

    document.documentElement.style.setProperty('--app-primaryColor', primary)
    document.documentElement.style.setProperty('--app-secondaryColor', secondary)
    document.documentElement.style.setProperty('--app-titleTextColor', text)
    document.documentElement.style.setProperty('--app-navBgColor', color?.navBg || primary)
    document.documentElement.style.setProperty('--app-navTextColor', color?.navText || fallbackColor.navText)
    document.documentElement.style.setProperty('--app-navAuthBgColor', color?.navAuthBg || '#fff')
    document.documentElement.style.setProperty('--app-navAuthTextColor', color?.navAuthText || primary)
  }

  return (
    <ThemeContext.Provider
      value={{
        admin,
        collapsed,
        collapseHandler,
      }}
    >
      {themeQuery.loading ? (
        <FullScreenLoading />
      ) : (
        <>
          <Helmet>
            <title>{admin.text?.webTitle}</title>
          </Helmet>
          {children}
        </>
      )}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider

export const useThemeContext = () => useContext(ThemeContext)
