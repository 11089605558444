import React, { useState } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'

import UploadImage from 'components/UploadImage'
import HooraySubmitButton from 'components/HooraySubmitButton'
import SystemFormInput from 'components/SystemFormInput'

import { commonFormProps } from 'utils/antd'

import type { SystemFormProps } from './interface'
import type { ImageProps } from 'components/UploadImage/interface'

const SystemForm: React.FC<SystemFormProps> = ({ systemFormData, namePrefixList, onFinish }) => {
  const { t } = useTranslation('systemForm')

  const [image, setImage] = useState<ImageProps | null>(null)

  const onUpload = (image: ImageProps) => {
    setImage(image)
  }

  return (
    <Form
      {...commonFormProps}
      name="systemForm"
      labelAlign="left"
      initialValues={systemFormData}
      onFinish={(value) =>
        onFinish({
          ...value,
          profilePictureFileKey: image?.fileKey,
        })
      }
    >
      <Form.Item label={t('label.profilePictureFileKey.title')}>
        <UploadImage name="profilePictureFileKey" value={systemFormData.profilePictureFileKey} onUpload={onUpload} />
      </Form.Item>

      <SystemFormInput namePrefixList={namePrefixList} isEmailDisabled />

      <Form.Item>
        <HooraySubmitButton />
      </Form.Item>
    </Form>
  )
}

export default SystemForm
