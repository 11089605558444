export const applicationSchema = `
  name
  appKey
  attribute
  status
  contactEmailList
  configurationList {
    configName
    configKey
    value {
      publicEndpoint
      bucket
      serviceEndpoint
    }
  }
`

export const credentialSchema = `
  name
  type
  credentialKey
  status
`

export const credentialSchemaWithAdminInformation = `
  ${credentialSchema}
  secretKey
  expiration {
    access
    refresh
  }
  isAdminCredential
  hostList
`
