import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import DefaultLayout from 'layouts/DefaultLayout'

import useRegisterEmail from 'graphQL/useRegisterEmail'

import apiPath from 'config/api'
import { authorizationKey } from 'config'
import { routePaths } from 'pages/routeConfig'

import useRole from 'hooks/useRole'
import useQueryString from 'hooks/useQueryString'

import RoleSelection from 'components/RoleSelection'
import RegisterForm from './RegisterForm'

import type { RegisterProps } from './interface'
import useVerifyInviteOrganization from 'graphQL/useVerifyInviteOrganization'

const Register: React.FC<RegisterProps> = ({ app }) => {
  const { t } = useTranslation('platform')
  const history = useHistory()

  const [roleKey, setRoleKey] = useState<string | undefined>()

  const query = useQueryString()
  const code = query.get(authorizationKey.code)
  const roleKeyQuery = query.get('role_key')
  const credentialKey = query.get(authorizationKey.credential)

  const role = useRole({
    credentialKey,
    roleKey,
  })

  const [registerEmail, registerEmailResp] = useRegisterEmail({
    onCompleted() {
      if (code) {
        verifyInviteOrganization({
          variables: {
            code,
          },
        })
      } else {
        message.success(t('register:registerSuccessText'))

        history.push({
          pathname: routePaths.credentialApplicationSignIn,
          search: window.location.search,
        })
      }
    },
  })

  const [verifyInviteOrganization] = useVerifyInviteOrganization({
    onCompleted() {
      message.success(t('verifyInviteOrganization:verifySuccessText'))

      history.push({
        pathname: routePaths.credentialApplicationSignIn,
        search: window.location.search,
      })
    },
    onError(error) {
      message.error(error.message)
    },
    context: {
      headers: {
        credentialKey,
      },
    },
  })

  return (
    <DefaultLayout loading={role.loading} platformName={app?.app.name || t('title')}>
      <RegisterForm
        key={roleKey}
        loading={registerEmailResp.loading}
        dataKeyList={role.dataKeyList}
        fieldList={role.userFields}
        onFinish={(data) => {
          const { email, password, confirmPassword, ...attribute } = data

          registerEmail({
            variables: {
              input: {
                email,
                password,
                confirmPassword,
                attribute: {
                  ...attribute,
                  dateOfBirth: dayjs(data.dateOfBirth).toISOString(),
                },
                roleKey: roleKeyQuery || '',
              },
            },
            context: {
              uri: apiPath.core.client,
              headers: {
                credentialKey: app?.credential.credentialKey,
              },
            },
          })
        }}
      />
      {role.roleList.length > 1 && <RoleSelection {...role} onSelect={(value) => setRoleKey(value)} />}
    </DefaultLayout>
  )
}

export default Register
