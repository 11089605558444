import React from 'react'
import { Button, ButtonProps } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const HooraySubmitButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation('global')

  return (
    <HooraySubmitButtonWrapper>
      <Button type="primary" htmlType="submit" {...props}>
        {t('global:saveButton')}
      </Button>
    </HooraySubmitButtonWrapper>
  )
}

export default HooraySubmitButton

const HooraySubmitButtonWrapper = styled.div`
  text-align: right;
`
