import React from 'react'
import styled from 'styled-components'

import { useThemeContext } from 'contexts/ThemeProvider/ThemeProvider'

const Logo: React.FC = () => {
  const theme = useThemeContext()

  const isHaveAnImage = Boolean(theme.admin.image?.navAuthCollapsedLogo && theme.admin.image?.navAuthLogo)

  return (
    <LogoWrapper>
      {isHaveAnImage ? (
        <Image
          src={theme.collapsed ? theme.admin.image?.navAuthCollapsedLogo : theme.admin.image?.navAuthLogo}
          alt={theme.admin.text?.navTitle}
        />
      ) : (
        renderFallbackText()
      )}
    </LogoWrapper>
  )

  function renderFallbackText() {
    if (theme.collapsed) {
      return 'A'
    }

    return 'Admin Panel'
  }
}

export default Logo

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin: 16px;
`

const Image = styled.img`
  width: auto;
  height: 32px;
`
