import React, { createContext, useState, useContext } from 'react'

import { appLocalAccessToken, appLocalRefreshToken, clearAppLocal } from 'utils/localService'
import { checkTokenExpired, decodeToken } from 'utils/token'

import type { TokenPayload } from 'utils/token/interface'

import type { AuthContextData, Token } from './interface'

const defaultAuthContextData: AuthContextData = {
  token: null,
  email: null,
  profilePictureFileKey: undefined,
  isLoggedIn: false,
  signIn() {},
  signOut() {},
  onProfileImageChange() {},
}

const AuthContext = createContext<AuthContextData>(defaultAuthContextData)

const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<Token | null>(defaultAuthContextData.token)
  const [email, setEmail] = useState(defaultAuthContextData.email)
  const [profilePictureFileKey, setProfilePictureFileKey] = useState<string | undefined>()

  const resetAuth = () => {
    setToken(null)
    setEmail(null)
  }

  const onProfileImageChange = (profileImageKey: string | undefined) => {
    setProfilePictureFileKey(profileImageKey)
  }

  const signIn = (authToken: Token) => {
    const tokenPayload = decodeToken<TokenPayload>(authToken.accessToken)

    appLocalAccessToken.set(authToken.accessToken)
    appLocalRefreshToken.set(authToken.refreshToken)

    setToken(authToken)
    setEmail(tokenPayload.email.value)
  }

  const signOut = () => {
    resetAuth()
    clearAppLocal()
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        email,
        profilePictureFileKey,
        isLoggedIn: !checkTokenExpired(appLocalAccessToken.get()),
        signIn,
        signOut,
        onProfileImageChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuthContext = () => useContext(AuthContext)
