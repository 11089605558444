import { useQuery } from '@apollo/client'

import QUERY_DOCUMENT from './theme'

import type { APIPayloadResponse, FindDataInput, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { ThemeAPIPayload } from './interface'

interface ThemeData {
  getTheme: APIPayloadResponse<ThemeAPIPayload[]>
}

interface ThemeVars {
  host: string
  find?: FindDataInput
}

const useGetTheme: GraphQLServiceQueryHook<ThemeData, ThemeVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    ...options,
  })
}

export default useGetTheme
