import React, { useEffect } from 'react'
import { Button, Result } from 'antd'
import { StringParam, useQueryParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'

import DefaultLayout from 'layouts/DefaultLayout'

import useVerifyEmail from 'graphQL/useVerifyEmail'

import { routePaths } from 'pages/routeConfig'

const VerifyEmailPage: React.FC = () => {
  const [token] = useQueryParam('token', StringParam)
  const [email] = useQueryParam('email', StringParam)

  const history = useHistory()

  const [verifyEmail, verifyEmailResp] = useVerifyEmail()

  const isSuccess = token && email && !verifyEmailResp.error && !verifyEmailResp.loading

  const status = isSuccess ? 'success' : 'error'
  const title = isSuccess ? 'Successfully Verified email.' : 'Email verify failed. Please try again later.'

  useEffect(() => {
    if (token && email) {
      verifyEmail({
        variables: {
          input: {
            email,
            verifyToken: token,
          },
        },
      })
    }
  }, [token, email, verifyEmail])

  return (
    <DefaultLayout platformName="Hooray.site">
      <Result
        status={status}
        title={title}
        extra={[
          <Button type="primary" key="console" onClick={() => history.push(routePaths.credentialApplicationSignIn)}>
            Back to sign in
          </Button>,
        ]}
      />
    </DefaultLayout>
  )
}

export default VerifyEmailPage
