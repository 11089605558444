import React from 'react'
import styled from 'styled-components'

interface FontawesomeIconProps extends React.HTMLAttributes<HTMLElement> {
  iconName: string
}

const FontawesomeIcon: React.FC<FontawesomeIconProps> = ({ iconName, ...props }) => {
  const menuIconClass = `fas fa-${iconName} ant-menu-item-icon`

  return <Icon {...props} className={menuIconClass} />
}

export default FontawesomeIcon

const Icon = styled.i`
  color: var(--app-primaryColor);
`
