export const s3Pathname = 'hooray-account/'

export const authorizationKey = {
  clientID: 'client_id',
  credential: 'credential_key',
  authType: 'auth_type',
  redirectUrl: 'redirect_url',
  code: 'code',
  error: 'error',
}
