import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { message } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useAuthContext } from 'contexts/AuthProvider'

import SignInCard from 'components/SignInCard'
import LoadingIcon from 'components/LoadingIcon'

import useGetAppByEmail from 'graphQL/useGetAppByEmail'
import useResetPasswordEmail from 'graphQL/useResetPasswordEmail'
import useLoginEmail from 'graphQL/useLoginEmail/useLoginEmail'

import DefaultLayout from 'layouts/DefaultLayout'

import useQueryString from 'hooks/useQueryString'

import { authorizationKey } from 'config'
import { routePaths } from 'pages/routeConfig'

import { appLocalApp } from 'utils/localService'

import type { AuthType } from 'graphQL/useLoginEmail/interface'

const ApplicationSignInPage: React.FC = () => {
  const { t } = useTranslation('applicationSignIn')

  const auth = useAuthContext()
  const history = useHistory()

  const query = useQueryString()
  const email = query.get('email')
  const authType = query.get(authorizationKey.authType) as AuthType
  const redirectUrl = query.get(authorizationKey.redirectUrl)

  const [buttonSubmitIndex, setButtonSubmitIndex] = useState(0)

  const appByEmailQuery = useGetAppByEmail({
    skip: !email,
    variables: {
      email: String(email),
    },
    onCompleted(resp) {
      if (!resp.getAppByEmail.payload) {
        message.error(t('noApplicationText'))
      }
    },
  })

  const [resetPasswordEmail, resetPasswordEmailResp] = useResetPasswordEmail({
    onCompleted() {
      message.success(
        t('resetPasswordSuccessText', {
          email,
        })
      )
    },
    onError() {
      message.error(t('resetPasswordFailedText'))
    },
  })

  const [loginEmail, loginEmailResp] = useLoginEmail({
    onCompleted(resp) {
      const { token, authType, credentialKey, code, redirectUrl: url } = resp.loginEmail.payload

      auth.signIn(token)

      if (authType === 'AUTH_CODE') {
        const callbackUrl = `${url}?${authorizationKey.code}=${code}&${authorizationKey.authType}=${authType}&${authorizationKey.credential}=${credentialKey}`

        window.open(callbackUrl, '_self')
      } else {
        history.push(routePaths.general)
      }
    },
    onError(error) {
      if (redirectUrl) {
        window.open(`${redirectUrl}?permission=no`, '_self')
      } else {
        message.error(error.message)
      }
    },
  })

  const isEmailHasApplication = !!appByEmailQuery.data?.getAppByEmail.payload

  const isLoading = appByEmailQuery.loading || !appByEmailQuery.data?.getAppByEmail

  if (!email || (!isEmailHasApplication && !appByEmailQuery.loading) || appByEmailQuery.error) {
    return <Redirect to={routePaths.signIn} />
  }

  return (
    <DefaultLayout platformName="Hooray.site">
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          {t('signingInEmailText')}
          <CurrentUserEmailSignIn className="bold">{email}</CurrentUserEmailSignIn>
          {appByEmailQuery.data?.getAppByEmail.payload.map(({ app, credential }, index) => {
            const isSystemAdmin = credential === null

            return (
              <SignInCard
                key={app.appKey}
                index={app.appKey}
                title={app.name}
                email={email}
                subtitle={t('signIn:password.title')}
                inputIcon="key"
                inputType="password"
                buttonName={t('signIn:password.button')}
                inputPlaceholder={t('global:inputPlaceholder', {
                  placeholderName: t('signIn:password.name'),
                })}
                buttonLoading={
                  buttonSubmitIndex === index && (resetPasswordEmailResp.loading || loginEmailResp.loading)
                }
                isSystemAdmin={isSystemAdmin}
                onFinish={(value) => {
                  setButtonSubmitIndex(index)

                  appLocalApp.set(
                    JSON.stringify({
                      app,
                      credential,
                    })
                  )

                  loginEmail({
                    variables: {
                      ...value,
                      email,
                      authType: authType || 'AUTH_TOKEN',
                      redirectUrl,
                    },
                    context: {
                      headers: {
                        credentialKey: isSystemAdmin ? '' : credential.credentialKey,
                      },
                    },
                  })
                }}
                onForgotPassword={(email) => {
                  resetPasswordEmail({
                    variables: {
                      email,
                    },
                    context: {
                      headers: {
                        credentialKey: credential.credentialKey,
                      },
                    },
                  })
                }}
              />
            )
          })}
        </>
      )}
    </DefaultLayout>
  )
}

export default ApplicationSignInPage

const CurrentUserEmailSignIn = styled.div`
  font-size: 18px;
`
