import React from 'react'

import SignInPage from 'pages/signIn'
import ApplicationSignInPage from 'pages/applicationSignIn'
import CredentialApplicationSignInPage from 'pages/credentialApplicationSignIn'
import GeneralPage from 'pages/general'
import SecurityPage from 'pages/security'
import SettingPage from 'pages/setting'
import RegisterPage from 'pages/register'
import ResetPasswordPage from 'pages/resetPassword'
import VerifyEmailPage from 'pages/verifyEmail'
import VerifyInviteOrganizationPage from 'pages/verifyInviteOrganization'

type RouteKey =
  | 'signIn'
  | 'register'
  | 'resetPassword'
  | 'resendEmail'
  | 'verifyEmail'
  | 'verifyInvitationOrganization'
  | 'applicationSignIn'
  | 'credentialApplicationSignIn'
  | 'general'
  | 'security'
  | 'setting'
  | 'signOut'

interface RouteConfig {
  key: RouteKey
  path: string
  component: React.ComponentType
}

type HoorayAccountPath = {
  [key in RouteKey]: string
}

export const routePaths: HoorayAccountPath = {
  signIn: '/sign-in',
  register: '/register',
  resetPassword: '/reset-password',
  resendEmail: '/resend-email',
  verifyEmail: '/verify-email',
  verifyInvitationOrganization: '/verify-invite-organization',
  applicationSignIn: '/application-sign-in',
  credentialApplicationSignIn: '/credential-application-sign-in',
  general: '/general',
  security: '/security',
  setting: '/setting',
  signOut: '/sign-out',
}

export const nonRequiredExternalAuthenticated: RouteConfig[] = [
  {
    key: 'register',
    path: routePaths.register,
    component: RegisterPage,
  },
  {
    key: 'credentialApplicationSignIn',
    path: routePaths.credentialApplicationSignIn,
    component: CredentialApplicationSignInPage,
  },
]

export const nonRequiredAuthenticationRoutes: RouteConfig[] = [
  {
    key: 'signIn',
    path: routePaths.signIn,
    component: SignInPage,
  },
  {
    key: 'applicationSignIn',
    path: routePaths.applicationSignIn,
    component: ApplicationSignInPage,
  },
  {
    key: 'resetPassword',
    path: routePaths.resetPassword,
    component: ResetPasswordPage,
  },
  {
    key: 'verifyEmail',
    path: routePaths.verifyEmail,
    component: VerifyEmailPage,
  },
  {
    key: 'verifyInvitationOrganization',
    path: routePaths.verifyInvitationOrganization,
    component: VerifyInviteOrganizationPage,
  },
]

export const privateRoutes: RouteConfig[] = [
  {
    key: 'general',
    path: routePaths.general,
    component: GeneralPage,
  },
  {
    key: 'security',
    path: routePaths.security,
    component: SecurityPage,
  },
  {
    key: 'setting',
    path: routePaths.setting,
    component: SettingPage,
  },
]
