import React from 'react'
import { Button, Form, Input } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { routePaths } from 'pages/routeConfig'

import FontawesomeIcon from 'components/FontAwesomeIcon'

import type { CredentialApplicationSignInCardProps, InputProps } from './interface'

const CredentialApplicationSignInCard: React.FC<CredentialApplicationSignInCardProps> = ({
  onFinish,
  onForgotPassword,
  buttonLoading,
}) => {
  const { t } = useTranslation('credentialApplicationSignIn')

  const inputList: InputProps[] = [
    {
      icon: 'user',
      type: 'email',
      placeholder: t('global:inputPlaceholder', {
        placeholderName: t('label.email'),
      }),
    },
    {
      icon: 'key',
      type: 'password',
      placeholder: t('global:inputPlaceholder', {
        placeholderName: t('label.password'),
      }),
    },
  ]

  return (
    <CredentialApplicationSignInCardWrapper>
      <Form style={{ width: '100%', maxWidth: '500px' }} onFinish={onFinish}>
        <Form.Item>
          <SignInTitle className="bold">{t('title')}</SignInTitle>
        </Form.Item>

        {inputList.map(({ icon, ...input }, index) => (
          <Form.Item name={input.type} key={index} rules={[{ required: true, message: t('global:requiredText') }]}>
            <Input {...input} prefix={<FontawesomeIcon iconName={icon} />} />
          </Form.Item>
        ))}

        <Form.Item>
          <ForgotPasswordTitle onClick={onForgotPassword}>{t('forgotPasswordTitle')}</ForgotPasswordTitle>
        </Form.Item>

        <RegisterWrapper>
          <span>{t('registerTitle')}</span>
          <RegisterLink
            to={{
              pathname: routePaths.register,
              search: window.location.search,
            }}
          >
            {t('button.register')}
          </RegisterLink>
        </RegisterWrapper>

        <Form.Item>
          <Button block loading={buttonLoading} type="primary" htmlType="submit">
            {t('button.signIn')}
          </Button>
        </Form.Item>
      </Form>
    </CredentialApplicationSignInCardWrapper>
  )
}

export default CredentialApplicationSignInCard

const CredentialApplicationSignInCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
`

const SignInTitle = styled.span`
  font-size: 35px;
`

const ForgotPasswordTitle = styled.span`
  color: var(--app-primaryColor);
  cursor: pointer;
`

const RegisterWrapper = styled(Form.Item)`
  display: none;

  @media (max-width: 1500px) {
    display: block;
  }
`

const RegisterLink = styled(Link)`
  margin-left: 16px;
  color: var(--app-primaryColor);
  cursor: pointer;
  text-decoration: underline;
`
