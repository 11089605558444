import { gql } from '@apollo/client'

const LOGIN_EMAIL = gql`
  mutation loginEmail($email: String!, $password: String!, $authType: ENUM_AUTH_TYPE!, $redirectUrl: String) {
    loginEmail(email: $email, password: $password, authType: $authType, redirectUrl: $redirectUrl) {
      message
      code
      payload {
        token {
          accessToken
          refreshToken
        }
        profile {
          email {
            value
            verifyStatus
          }
          attribute
        }
        code
        authType
        redirectUrl
        credentialKey
      }
    }
  }
`

export default LOGIN_EMAIL
