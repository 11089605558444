import React, { useState } from 'react'
import dayjs from 'dayjs'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAuthContext } from 'contexts/AuthProvider'

import ContentCard from 'components/ContentCard'
import PageTitle from 'components/PageTitle'
import SystemForm from './SystemForm'
import GeneralForm from './GeneralForm'

import useMasterData from 'hooks/useMasterData'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import useGetMyProfile from 'graphQL/useGetMyProfile'
import useUpdateMyProfile from 'graphQL/useUpdateMyProfile'

import { removeUndefinedObjectData } from 'utils/object'

import type { ButtonSaveActionName, GeneralProps } from './interface'

const General: React.FC<GeneralProps> = ({ dataKeyList, fieldList }) => {
  const { t } = useTranslation('general')

  const auth = useAuthContext()

  const myProfileQuery = useGetMyProfile()

  const profileData = myProfileQuery.data?.getMyProfile.payload
  const attribute = profileData?.attribute

  const [buttonSaveActionName, setButtonSaveActionName] = useState<ButtonSaveActionName>()

  const { loading, masterDataList } = useMasterData({
    parentKeys: [...dataKeyList, 'DIALING_CODE', 'NAME_PREFIX'],
  })

  const [updateMyProfile, updateMyProfileResp] = useUpdateMyProfile({
    onCompleted(resp) {
      message.success(t('updateProfileSuccessText'))

      auth.onProfileImageChange(resp.updateMyProfile.payload.attribute?.profilePictureFileKey)

      myProfileQuery.refetch()
    },
  })

  return (
    <AuthenticatedLayout>
      <PageTitle isRootPage title={t('title')} />
      <ContentCard title={t('cardTitle.system')} loading={myProfileQuery.loading || loading}>
        {(!myProfileQuery.loading || !loading) && (
          <SystemForm
            systemFormData={{
              ...attribute,
              email: profileData?.email[0].value,
            }}
            namePrefixList={masterDataList.NAME_PREFIX}
            onFinish={(data) => {
              setButtonSaveActionName('System')
              const filteredUndefinedData = removeUndefinedObjectData(data)

              updateMyProfile({
                variables: {
                  input: {
                    attribute: {
                      ...attribute,
                      ...filteredUndefinedData,
                    },
                  },
                },
              })
            }}
            loading={updateMyProfileResp.loading && buttonSaveActionName === 'System'}
          />
        )}
      </ContentCard>

      {fieldList.length > 0 && (
        <ContentCard title={t('cardTitle.general')} loading={myProfileQuery.loading || loading}>
          {(!myProfileQuery.loading || !loading) && (
            <GeneralForm
              fieldList={fieldList}
              masterDataList={masterDataList}
              generalFormData={{
                ...attribute,
              }}
              onFinish={(data) => {
                setButtonSaveActionName('General')
                const filteredUndefinedData = removeUndefinedObjectData(data)

                updateMyProfile({
                  variables: {
                    input: {
                      attribute: {
                        ...attribute,
                        ...filteredUndefinedData,
                        dateOfBirth: dayjs(data.dateOfBirth).toISOString(),
                      },
                    },
                  },
                })
              }}
              loading={updateMyProfileResp.loading && buttonSaveActionName === 'General'}
            />
          )}
        </ContentCard>
      )}
    </AuthenticatedLayout>
  )
}

export default General
